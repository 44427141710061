<template>
  <section>
    <q-btn-toggle
      v-model="checkoutStore.isPickup"
      :options="[
        { label: 'Ship Order', icon: mdiTruck, value: false },
        { label: 'Pick Up In-Store', icon: mdiStore, value: true },
      ]"
      :disable="quoteStore.pending"
      toggle-color="primary"
      square
      spread
    />

    <q-stepper-navigation>
      <q-btn
        :disable="!canContinue || quoteStore.pending"
        color="primary"
        label="Continue"
        square
        @click="checkoutStore.goToNext()"
      />
    </q-stepper-navigation>
  </section>
</template>

<script lang="ts" setup>
import { mdiTruck, mdiStore } from '@quasar/extras/mdi-v7';

const checkoutStore = useCheckoutStore();
const quoteStore = useQuoteStore();

const canContinue = computed(() => !quoteStore.error);
</script>
